import { APP_INITIALIZER, NgModule } from '@angular/core';
import { MAT_FORM_FIELD_DEFAULT_OPTIONS } from '@angular/material/form-field';
import { MAINTENANCE_CHECK_BASE_URL } from '@campus/browser';
import {
  AuthService,
  AUTH_SERVICE,
  GRECAPTCHA_TOKEN,
  PersonApi,
  PORTAL_ENVIRONMENT_TOKEN,
} from '@campus/public-portal';
import { environment } from '../environments/environment';
import { StudentCodeAdapter, STUDENT_CODE_ADAPTER } from './services/auth/student-code.adapter';
import { UrlLoginAdapter, URL_LOGIN_ADAPTER } from './services/auth/url-login.adapter';

@NgModule({
  providers: [
    {
      provide: GRECAPTCHA_TOKEN,
      useFactory: () => {
        return window['grecaptcha'] as ReCaptchaV2.ReCaptcha; // google recaptcha script is loaded in index.html
      },
    },
    {
      provide: MAINTENANCE_CHECK_BASE_URL,
      useValue: '/',
    },
    {
      provide: APP_INITIALIZER,
      useFactory: (authService: PersonApi) => () => authService.getCurrent().toPromise(),
      multi: true,
      deps: [PersonApi],
    },
    {
      provide: AUTH_SERVICE,
      useClass: AuthService,
    },
    {
      provide: PORTAL_ENVIRONMENT_TOKEN,
      useValue: environment,
    },
    {
      provide: STUDENT_CODE_ADAPTER,
      useClass: StudentCodeAdapter,
    },
    {
      provide: URL_LOGIN_ADAPTER,
      useClass: UrlLoginAdapter,
    },
    {
      provide: MAT_FORM_FIELD_DEFAULT_OPTIONS,
      useValue: { appearance: 'outline' },
    },
  ],
})
export class AppTokenModule {}
